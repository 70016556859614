import Link from 'next/link';
import { withRouter } from 'next/router';
import React, { Children } from 'react';
import ErrorBoundary from '../errorboundary/errorboundary.component';

const ActiveLink = (linksprops) => {

  const { router, children, ...props } = linksprops;
  
  let pinCode = router?.query?.pinCode;

  let href = props.href + '';
  if (pinCode) {
    if (href.includes('?'))
      href = href + '&pinCode=' + pinCode;
    else href = href + '?pinCode=' + pinCode;
  }
  return (
    <ErrorBoundary>
      <Link as={href} href={href} passHref >{children}</Link>
    </ErrorBoundary>
  );
};

export default withRouter(ActiveLink);
export const _ActiveLink = withRouter(ActiveLink);