import AxiosService from '../../base/portal_env_config/axiosService';
import { paths } from '../../base/portal_env_config/urlconstants';
import { AppConstants } from '../../base/portal_env_config/appconstants';
import { BaseResponse } from '../../model/base/baseresponse';

export default class DiagnosticService {

    public static TYPE_MOST_BOOKED_LIST = 1;
    public static TYPE_PACKAGE_LIST = DiagnosticService.TYPE_MOST_BOOKED_LIST + 1;
    public static TYPE_TEST_LIST = DiagnosticService.TYPE_PACKAGE_LIST + 1;
    public static TYPE_FILTER_LIST = DiagnosticService.TYPE_TEST_LIST + 1;
    public static TYPE_PROFILE_LIST = DiagnosticService.TYPE_FILTER_LIST + 1;

    public static uploadedFiles = [];

    public static async getDiagnosticWalkinTestList(investigationRequest): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.DIAGNOSTIC_WALKIN_TEST_LIST, investigationRequest).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getMostBookedWalkinTests(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.DIAGNOSTIC_MOST_BOOKED_WALKIN_TESTS, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getAdvertisementList(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.FETCH_ADVERTISEMENT_LIST, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getDiagnosticWalkinTestCategories(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.DIAGNOSTIC_WALKIN_TEST_CATEGORIES, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getDiagnosticPackagesOrProfiles(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.DIAGNOSTIC_PACKAGES_OR_PROFILES, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getPackageDetails(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.PACKAGE_TEST_DETAILS, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getDiagnosticPOCList(params): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_DIAGNOSTIC_POC_LIST, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    /* public static async searchTest(params): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.DIAGNOSTIC_SEARCH_TEST, params).then((res) => {
                console.log("Result: ", res);
                return Promise.resolve(res);
            }).catch(err => {
                if (err) {
                    console.log(err);
                    return Promise.reject(err);
                }
            });
    } */

    public static async getWalkInSlots(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_DIAGNOSTIC_WALKIN_SLOT, params).then((data) => {
            return Promise.resolve(data);
        }).catch((err) => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }
    public static async getHomeCollectionSlots(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_DIAGNOSTIC_HOMECOLLECTION_SLOT, params).then((data) => {
            return Promise.resolve(data);
        }).catch((err) => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getPOCBasedServiceList(params): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_SERVICE_LIST_BASEDON_POC, params,
            AppConstants.ELASTIC_URL_INDEX).then((res) => {
                console.log("Result: ", res);
                return Promise.resolve(res);
            }).catch(err => {
                if (err) {
                    console.log(err);
                    return Promise.reject(err);
                }
            });
    }

    public static async raiseRequest(requestBody): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.RAISE_ORDER_REQUEST, requestBody).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getDiagnosticCentresList(params): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_DIAGNOSTIC_CENTRES_LIST, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getDiagnosticCentreDetails(request): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_DIAGNOSTIC_CENTRE_DETAILS, request).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    // public static async getDiagnosticCentreDetails(request): Promise<BaseResponse> {
    //     return AxiosService.httpGet(paths.GET_DIAGNOSTIC_CENTRE_DETAILS, request).then((res) => {
    //         return Promise.resolve(res);
    //     }).catch(err => {
    //         if (err) {
    //             console.log(err);
    //             return Promise.reject(err);
    //         }
    //     });
    // }

    //GET_REGIONS
    public static async getRegions(params): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_REGIONS, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getServiceableRegions(params): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_SERVICEABLE_REGIONS, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getTestSuggestions(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_TEST_SUGGESTIONS, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getUpcomingTests(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.UPCOMING_TEST_NOTIFICATION, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    // public static async getPocGoolgeReviews(pocId): Promise<BaseResponse> {
    //     return AxiosService.httpGet(paths.GET_GOOGLE_REVIEWS_FOR_POC, { pocId: pocId }).then((res) => {
    //         return Promise.resolve(res);
    //     }).catch(err => {
    //         if (err) {
    //             console.log(err);
    //             return Promise.reject(err);
    //         }
    //     });
    // }

}